<template>
  <select
    v-model='selectedFundId'
    class='relative form-select'
    style='width:12rem;'
    @focus='$emit("focus")'
    @change='updateField'>
    <option
      v-for='fund in fundOptions'
      :key='`select-row-${rowIndex}-${colKey}-${fund.value}`'
      :value='fund.value'>
      {{fund.text}}
    </option>
  </select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TableTypeCustomFieldFund',
  props: ['row', 'rowIndex', 'colKey'],
  data () {
    return {
      selectedFundId: this.row[this.colKey]
    }
  },
  computed: {
    ...mapState('funds', [
      'funds',
    ]),
    fundOptions () {
      return this.funds.map(fund => {
        return {
          text: fund.short_name,
          value: fund.id
        }
      })
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.selectedFundId)
      this.$emit('blur')
    },
  },
}
</script>
